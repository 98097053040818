import { render, staticRenderFns } from "./Blog.vue?vue&type=template&id=a506a184&scoped=true"
import script from "./Blog.vue?vue&type=script&lang=js"
export * from "./Blog.vue?vue&type=script&lang=js"
import style0 from "./Blog.vue?vue&type=style&index=0&id=a506a184&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a506a184",
  null
  
)

export default component.exports