<template>
  <section>
    <div
      class="headbanner"
      :style="`background-image:url(${bannerUrl});`"
    ></div>

    <v-container class="container-custom">
      <v-row v-if="datanumber == 999">
        <v-col md="4">
          <v-skeleton-loader
            max-width="420"
            type="image"
            height="200"
          ></v-skeleton-loader>
          <v-skeleton-loader
            max-width="420"
            type="image"
            height="200"
          ></v-skeleton-loader>
        </v-col>
        <v-col md="4">
          <v-skeleton-loader
            max-width="420"
            type="image"
            height="200"
          ></v-skeleton-loader>
          <v-skeleton-loader
            max-width="420"
            type="image"
            height="200"
          ></v-skeleton-loader>
        </v-col>
        <v-col md="4">
          <v-skeleton-loader
            max-width="420"
            type="image"
            height="200"
          ></v-skeleton-loader>
          <v-skeleton-loader
            max-width="420"
            type="image"
            height="200"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row v-if="datanumber == 0" class="divbox"> No Data Available </v-row>
      <v-row
        v-if="allPosts.results != undefined && datanumber > 0"
        class="blog-section"
      >
        <v-col
          md="4"
          v-for="(item, index) in blogdata.posts"
          :key="index"
          :class="{ hidediv: item.category != active }"
        >
          <div class="marginbtm" v-if="item.category == active">
            <div class="jobslist">
              <Searchboxblog :training="item" />
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  
  </section>
</template>

<script>
import Searchboxblog from "@/components/frontend/SearchboxBlog";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Blog",
  components: { Searchboxblog },
  data: () => ({
    bannerUrl: require("@/assets/placeholder.jpg"),
    active: "",
    list: ["Career Tips", "Business Tips", "Training", "Education"],
    blogdata: [],
    datanumber: 999,
    showForm: false,
  }),

  computed: {
    ...mapGetters(["allPosts", "allAssets", "allMeta"]),
  },
  methods: {
    ...mapActions(["fetchPostFront", "getAssets", "getSingleMetaPage"]),
    fiterCategory(data) {
      this.active = data;
    },
    toggleForm() {
      this.showForm = !this.showForm;
    },
  },
  metaInfo() {
    return {
      title: this.allMeta && this.allMeta.title,
      meta: [
        {
          name: "description",
          content: this.allMeta && this.allMeta.description,
        },
        {
          name: "keywords",
          content: this.allMeta && this.allMeta.keywords,
        },
      ],
    };
  },
  async created() {
    await this.fetchPostFront("blog");
    this.blogdata = this.allPosts.results;
    if (this.$route.params.slug == "career-tips") {
      this.active = "Career Tips";
      this.datanumber = this.allPosts.results.posts.filter((ele) => {
        return ele.category == this.active;
      }).length;
    }
    if (this.$route.params.slug == "business-tips") {
      this.active = "Business Tips";
      this.datanumber = this.allPosts.results.posts.filter((ele) => {
        return ele.category == this.active;
      }).length;
    }
    if (this.$route.params.slug == "information") {
      this.active = "Information";
      this.datanumber = this.allPosts.results.posts.filter((ele) => {
        return ele.category == this.active;
      }).length;
    }
    if (this.$route.params.slug == "education") {
      this.active = "Education";

      this.datanumber = this.allPosts.results.posts.filter((ele) => {
        return ele.category == this.active;
      }).length;
    }
    await this.getAssets({
      title: "Blogs",
      connectedTo: "page",
    });
    this.bannerUrl = this.allAssets[0].image;
    await this.getSingleMetaPage(this.$route.params.slug);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.sidemenu {
  margin-left: 0;
  padding-left: 0;
  margin-top: 10px;
  li {
    margin: 0;
    width: 100%;
    padding: 10px 15px;
    font-size: $normalfont;
    box-shadow: 0px 1px 2px 1px rgba(7, 7, 7, 0.1);
    cursor: pointer;
  }
  .active {
    background: $tagcolor;
    color: $white;
  }
}
.table {
  box-shadow: 0px 1px 2px 1px rgba(7, 7, 7, 0.1);

  thead {
    border-bottom: 2px solid $menucolor;
    background: $tagcolor;
  }
  th {
    font-size: $normal_lfont !important;
    color: $white !important;
    font-size: $iconfont;
  }
  td {
    div {
      padding: 20px 10px;
    }
  }
  margin-bottom: 20px;
}
h1 {
  font-size: $titlefont;
  font-weight: 500;
  margin-bottom: 20px;
  margin-top: 20px;
}
.marbox {
  margin: 30px 0;
}
.divbox {
  padding: 30px 0;
  font-size: 20px;
}
.boxinside {
  box-shadow: 0 1px 1px 0 rgb(66 66 66 / 8%), 0 1px 3px 1px rgb(66 66 66 / 16%);
  transition: width 235ms 0ms cubic-bezier(0.4, 0, 0.2, 1);
  padding: 20px;
  background: $white;
  margin-bottom: 20px;
  padding: 20px;
  background: $white;
  margin-bottom: 20px;
  &:last-of-type {
    margin-bottom: 0;
  }
}
.logobox {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
  img {
    width: 150px;
  }
}
.aboutcontent {
  font-size: $midfont;
  margin-top: 10px;
}
.actionbutton {
  display: flex;
  justify-content: center;
  a {
    text-decoration: none;
  }
  .v-icon {
    font-size: $iconfont;
  }

  .socialshare {
    box-shadow: 0px 1px 14px 1px rgba(222, 222, 222, 1);
    padding: 10px;
    display: none;
    position: absolute;
    left: 0;
    top: 100%;
  }
  li {
    margin-left: 0;
    margin-right: 15px;
    position: relative;
    cursor: pointer;
    &:hover {
      .socialshare {
        display: flex;
        background: $white;
        flex-flow: row;
      }
    }
  }
}

.btn-actions {
  font-size: $normalfont;
  margin-top: 10px;
  li {
    margin-left: 0;
    padding: 10px;
    background: red;
    padding: 5px 20px;
    font-size: $midfont;
    cursor: pointer;
    border-radius: 25px;
    color: $white;
    &:first-of-type {
      background: $tagcolor;
    }
    &:nth-of-type(2) {
      background: $greentva;
    }
    &:nth-of-type(3) {
      background: $white;
      border: 1px solid $tagcolor;
      color: $tagcolor;
    }
  }
}
small {
  a {
    text-decoration: none;
    color: $tagcolor;
    .v-icon {
      font-size: $iconfont;
      text-decoration: none;
    }
  }
}
.mr-btm {
  margin-bottom: 40px;
}
.hidediv {
  display: none !important;
}

.headbanner {
  background-size: cover;
  height: 450px;
  width: 100%;
  background-position: center center;
}
@media screen and (max-width: 992px) {
  .headbanner {
    background-size: cover;
    height: 120px;
    width: 100%;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .headbanner {
    height: 40vh;
    width: 100%;
    background-position: center;
  }
  section {
    overflow: hidden;
  }
}
.review-header {
  .rating-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .rating-auth {
    display: flex;
    align-items: center;
    gap: 20px;
    span {
      background: #1876d2;
      width: 60px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      font-size: 30px;
      color: white;
    }
    .star-icon {
      color: #ffa001;
    }
  }
}
.review-likes {
  display: flex;
  justify-content: flex-end;
  padding: 10px 0;
  .likes {
    display: flex;
    align-items: center;
    gap: 15px;
    p {
      margin-top: 4px;
    }
    .like-button {
      display: flex;
      gap: 5px;
      cursor: pointer;
      align-items: center;
      .like-icon {
        font-size: 18px;
      }
    }
  }
}
.review-content {
  h3 {
    margin-top: 17px;
  }
}
.add-review-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 45px 0;
  width: 100%;
  .add-review-btn {
    border: 2px solid #757575;
    padding: 10px 20px;
    color: #757575;
    border-radius: 5px;
    .pen-icon {
      font-size: 17px;
    }
  }
}
.form-container {
  width: 60%;
  margin-top: 45px;
}
.form-div {
  display: flex;
  align-items: center;
  gap: 25px;
}
.form-btn {
  display: flex;
  justify-content: flex-end;
  padding: 10px 5px;
  width: 100%;
}
.form-rating-star {
  margin-bottom: 22px;
}
@media screen and (max-width: 600px) {
  .form-container {
    width: 100%;
  }
  .form-div {
    display: flex;
    flex-direction: column;
    align-items: normal;
    gap: 0px;
  }
}
.blog-section {
  padding: 20px 0 40px 0;
}
</style>
