<template>
  <section>
    <div>
      <router-link :to="'/singleBlog/' + training.slug">
        <img
          v-if="training.imageUrl"
          class="api_image"
          :src="'https://app.necojobs.com.np' + training.imageUrl"
          alt=""
          style="width: 100%; padding: 0" />
        <img
          v-else
          class="api_image"
          :src="require('@/assets/noimage.jpeg')"
          alt="No image available"
          style="width: 100%; padding: 0"
      /></router-link>
      <ul class="jobdetail nolist" style="padding: 0 10px">
        <li class="jobtitle">
          <router-link :to="'/singleBlog/' + training.slug" class="jobtitle">{{
            training.title.length > 90
              ? training.title.slice(0, 90) + "..."
              : training.title
          }}</router-link>
        </li>

        <li class="status" v-if="status">
          {{ status }}
        </li>
      </ul>
    </div>
    <footer>
      <ul class="actionbutton nolist">
        <li>
          Share
          <v-icon> mdi-share-variant </v-icon>
          <ul class="socialshare nolist">
            <li>
              <ShareNetwork
                network="facebook"
                :url="`https://www.necojobs.com.np/singleBlog/${training.slug}`"
                :title="training.title"
                :description="training.description.slice(0, 100)"
                quote="A better training plan"
                hashtags="necojobs,necojobs training"
              >
                <v-icon> mdi-facebook </v-icon>
              </ShareNetwork>
            </li>
            <li>
              <ShareNetwork
                network="whatsapp"
                :url="`https://www.necojobs.com.np/singleBlog/${training.slug}`"
                :title="training.title"
              >
                <v-icon> mdi-whatsapp </v-icon>
              </ShareNetwork>
            </li>
            <li>
              <ShareNetwork
                network="linkedin"
                :url="`https://www.necojobs.com.np/singleBlog/${training.slug}`"
                :title="training.title"
              >
                <v-icon> mdi-linkedin </v-icon>
              </ShareNetwork>
            </li>
          </ul>
        </li>

        <li>Posted At : {{ training.createdAt | formatDate }}</li>
      </ul>
    </footer>
  </section>
</template>

<script>
export default {
  name: "Searchboxblog",
  props: ["status", "training"],
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
section {
  background: $white !important;
  border: 1px solid rgb(230, 230, 230);
  box-shadow: 0 1px 1px 0 rgb(66 66 66 / 8%), 0 1px 3px 1px rgb(66 66 66 / 16%);
  border-radius: 5px;
  // overflow: hidden;
  div.row {
    background: $white;
    padding: 20px 0;
  }
}
.status {
  background: $tagcolor;
  display: block;
  width: 120px;
  text-align: center;
  padding: 2px;
  margin-top: 5px;
  border-radius: 30px;
  color: $white;
}
.actionbutton {
  display: flex;
  justify-content: space-between;
  background: $white;
  padding: 0;
  ul {
    padding: 0;
  }
  li {
    font-size: 14px;
    padding: 0;
  }
  a {
    text-decoration: none;
  }
  .v-icon {
    font-size: $iconfont;
    margin-left: 10px;
  }
  .socialshare {
    box-shadow: 0px 1px 14px 1px rgba(222, 222, 222, 1);
    padding: 10px;
    display: none;
    position: absolute;
    left: 0;
    top: 100%;
  }
  li {
    margin-left: 0;
    position: relative;
    cursor: pointer;
    &:hover {
      .socialshare {
        display: flex;
        background: $white;
        flex-flow: row;
      }
    }
  }
}
.nomargin {
  li {
    margin-right: 0;
    margin-left: 15px;
  }
}
footer {
  border-top: 1px solid #cfcccc;
  padding: 7px 10px;
}
.logobox {
  // padding: 10px;
  img {
    width: 100%;
    border: 1px solid #cccbcb;
  }
}
.jobdetail {
  display: flex;
  margin: 0;
  flex-flow: column;
  padding: 0;
  min-height: 55px;
  li {
    margin-bottom: 5px;
    a {
      color: $tagcolor;
      text-decoration: none;
      font-weight: 500;
    }
  }
  .jobtitle {
    a {
      font-size: 18px;
    }
  }
  .companyName {
    margin-bottom: 10px;
    a {
      font-size: $normal_lfont;
    }
  }
  .location {
    font-size: $normalfont;
    .v-icon {
      font-size: $normal_lfont;
    }
  }
}
.api_image {
  min-height: 160px;
  height: 160px;
  width: 100% !important;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  object-fit: cover;
}
.jobtitle {
  color: #424242 !important;
  text-decoration: none;
  font-weight: normal !important;
  font-size: 16px !important;
  &:hover {
    color: #4782da !important;
  }
}
</style>
